import { Box, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Image from "next/image";

const useStyles = makeStyles((theme) => ({
  logInButton: {
    marginTop: theme.spacing(6),
  },
}));

interface NotAuthenticatedProps {
  onClickLogIn: () => void;
}

const NotAuthenticated = ({ onClickLogIn }: NotAuthenticatedProps) => {
  const classes = useStyles();

  return (
    <Box
      paddingX={3}
      paddingY={12}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Image src="/images/nocd-logo.svg" width={300} height={87} />

      <Button
        className={classes.logInButton}
        onClick={onClickLogIn}
        size="large"
        color="primary"
        variant="contained"
      >
        Log in
      </Button>
    </Box>
  );
};

export default NotAuthenticated;
