import { useSession } from "@core/hooks/useSession";
import {
  api,
  getMemberAppointmentQueryKey,
  MemberAppointment,
  transformAPIError,
} from "@core/services/nocd-api";
import { useMutation, UseMutationResult } from "react-query";
import { useSWRConfig } from "swr";
import invariant from "tiny-invariant";

interface CopyDiamondAssessemntToSecondSessionPayload {
  appointment_id: number;
  protocol_type: string;
}

export const copyReassessmentDiamondAssessment = (
  payload: CopyDiamondAssessemntToSecondSessionPayload,
  accessToken: string | undefined
) => {
  invariant(payload.appointment_id != null, "appointment_id is required");
  return api
    .post<MemberAppointment>(
      `/v2/clinician/member_appointments/${encodeURIComponent(
        payload.appointment_id
      )}/copy_diamond_reassessment`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);
};

export const useCopyReassessmentDiamondAssessment = (): UseMutationResult<
  MemberAppointment,
  Error,
  CopyDiamondAssessemntToSecondSessionPayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const { mutate: globalMutate } = useSWRConfig();

  return useMutation(
    (payload) => copyReassessmentDiamondAssessment(payload, accessToken),
    {
      onSuccess: (_data, payload) => {
        return Promise.all([
          globalMutate(
            getMemberAppointmentQueryKey(payload.appointment_id, accessToken)
          ),
        ]);
      },
    }
  );
};
