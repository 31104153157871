import create from "zustand";
import { persist } from "zustand/middleware";

interface SidebarWidthState {
  sidebarWidth: number;
  isOpen: boolean;
  setSidebarWidth: (width: number) => void;
  toggleSidebar: () => void;
}

const useSidebarWidthStore = create<SidebarWidthState>(
  persist(
    (set) => ({
      sidebarWidth: 300, // default width sidebar open
      isOpen: true, // default state is open
      setSidebarWidth: (width: number) => set({ sidebarWidth: width }),
      toggleSidebar: () => set((state) => ({ isOpen: !state.isOpen })),
    }),
    {
      name: "sidebar-width",
      getStorage: () => localStorage,
    }
  )
);

export default useSidebarWidthStore;
