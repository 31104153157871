import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

const getSupervisorContactNoteQueryKey = (
  accessToken: string
): [string, string] => [`supervisor-contact-notes`, accessToken];

const deleteSupervisorContactNote = (
  noteId: number,
  accessToken: string | undefined
) =>
  api
    .delete<string>(
      `/v1/clinicians/supervisor_contact_notes/${noteId}`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);

export const useDeleteSupervisorNote = (): UseMutationResult<
  string,
  Error,
  number
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => deleteSupervisorContactNote(payload, accessToken),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(
          getSupervisorContactNoteQueryKey(accessToken)
        ),
    }
  );
};
