// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import LogRocket from "logrocket";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://fec35232bc2b489fac3f7036aeb482aa@o546899.ingest.sentry.io/5716029",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: process.env.NEXT_PUBLIC_ENVIRONMENT === "prod" ? 1.0 : 0.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  beforeSend(event) {
    const logRocketSession = LogRocket?.sessionURL;

    if (logRocketSession) {
      // eslint-disable-next-line no-param-reassign
      event.extra = event.extra ?? {};
      // eslint-disable-next-line no-param-reassign
      event.extra.LogRocket = logRocketSession;
    }

    return event;
  },
  // To prevent bots from spamming Sentry, we explicitly list which
  // domains can send errors.
  allowUrls: [
    /https?:\/\/((clinician-portal|clinician-portal-qa|ehr|ehr-qa)\.)?treatmyocd\.com/,
  ],
});
