import { Countries, ProtocolTypes } from "@core/types";

export const YES = "Yes";
export const NO = "No";
export const NOT_APPLICABLE = "Not Applicable";
export const DENIED = "Denied";
export const NA = "N/A";
export const PENDING = "Pending";
export const PREFER_NOT_TO_ANSWER = "Prefer not to answer";
export const NOT_AT_ALL = "Not at all";
export const PARTIALLY = "Partially";
export const MOSTLY = "Mostly";
export const ENTIRELY = "Entirely";
export const DECLINED_TO_SHARE = "Declined to share";

export const STATES: string[] = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District Of Columbia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const SUPPORTED_COUNTRIES: Countries[] = [
  Countries.UNITED_STATES,
  Countries.UNITED_KINGDOM,
  Countries.AUSTRALIA,
  Countries.CANADA,
  Countries.OTHER,
];

export enum EhrRoles {
  AUDITOR = "AUDITOR",
  BILLING = "BILLING",
  CARE_TEAM_MANAGER = "CARE_TEAM_MANAGER",
  CLINICAL_LEADERSHIP = "CLINICAL_LEADERSHIP",
  CLINICIAN = "CLINICIAN",
  CLINICIAN_SCHEDULE_APPROVER = "CLINICIAN_SCHEDULE_APPROVER",
  DEVELOPER = "DEVELOPER",
  INTAKE = "INTAKE",
  MEMBER_ADVOCATE = "MEMBER_ADVOCATE",
  MEMBER_ADVOCATE_AUDITOR = "MEMBER_ADVOCATE_AUDITOR",
  SCHEDULING = "SCHEDULING",
  CLINICIAN_SUPERVISOR = "CLINICIAN_SUPERVISOR",
  CLINICAL_LEAD_SPECIALTY_CONDITIONS = "CLINICAL_LEAD_SPECIALTY_CONDITIONS",
  FORM_BASELINE_CHANGE_REQUEST_ADMIN = "FORM_BASELINE_CHANGE_REQUEST_ADMIN",
  NETWORK_OPS_ADMIN = "NETWORK_OPS_ADMIN",
  ASSESSMENT_ARCHIVE_CHANGE_REQUEST_ADMIN = "ASSESSMENT_ARCHIVE_CHANGE_REQUEST_ADMIN",
  DIAGNOSTIC_APPROVER = "DIAGNOSTIC_APPROVER",
  CLINICIAN_PRO = "CLINICIAN_PRO",
  MEMBER_ADVOCATE_ADMIN = "MEMBER_ADVOCATE_ADMIN",
}

export enum TreatmentRecommendations {
  ACTIVE = "Active",
  MAINTENANCE = "Maintenance",
}

export const DEFAULT_CHAT_CONVERSATION_LIMIT = 50;
export const MEMBER_CHART_DRAWER_WIDTH = 240;

export const supportedCountriesCodes = [
  { value: "US", label: "United States" },
  { value: "UK", label: "United Kingdom" },
  { value: "AU", label: "Australia" },
  { value: "CA", label: "Canada" },
];

export const otherCountriesCodes = [
  { value: "AF", label: "Afghanistan" },
  { value: "AL", label: "Albania" },
  { value: "DZ", label: "Algeria" },
  { value: "AD", label: "Andorra" },
  { value: "AO", label: "Angola" },
  { value: "AG", label: "Antigua and Barbuda" },
  { value: "AR", label: "Argentina" },
  { value: "AM", label: "Armenia" },
  { value: "AT", label: "Austria" },
  { value: "AZ", label: "Azerbaijan" },
  { value: "BS", label: "Bahamas" },
  { value: "BH", label: "Bahrain" },
  { value: "BD", label: "Bangladesh" },
  { value: "BB", label: "Barbados" },
  { value: "BY", label: "Belarus" },
  { value: "BE", label: "Belgium" },
  { value: "BZ", label: "Belize" },
  { value: "BJ", label: "Benin" },
  { value: "BT", label: "Bhutan" },
  { value: "BO", label: "Bolivia" },
  { value: "BA", label: "Bosnia and Herzegovina" },
  { value: "BW", label: "Botswana" },
  { value: "BR", label: "Brazil" },
  { value: "BN", label: "Brunei" },
  { value: "BG", label: "Bulgaria" },
  { value: "BF", label: "Burkina Faso" },
  { value: "BI", label: "Burundi" },
  { value: "CV", label: "Cabo Verde" },
  { value: "KH", label: "Cambodia" },
  { value: "CM", label: "Cameroon" },
  { value: "CF", label: "Central African Republic" },
  { value: "TD", label: "Chad" },
  { value: "CL", label: "Chile" },
  { value: "CN", label: "China" },
  { value: "CO", label: "Colombia" },
  { value: "KM", label: "Comoros" },
  { value: "CG", label: "Congo (Congo-Brazzaville)" },
  { value: "CR", label: "Costa Rica" },
  { value: "HR", label: "Croatia" },
  { value: "CU", label: "Cuba" },
  { value: "CY", label: "Cyprus" },
  { value: "CZ", label: "Czechia (Czech Republic)" },
  { value: "CD", label: "Democratic Republic of the Congo (Congo-Kinshasa)" },
  { value: "DK", label: "Denmark" },
  { value: "DJ", label: "Djibouti" },
  { value: "DM", label: "Dominica" },
  { value: "DO", label: "Dominican Republic" },
  { value: "EC", label: "Ecuador" },
  { value: "EG", label: "Egypt" },
  { value: "SV", label: "El Salvador" },
  { value: "GQ", label: "Equatorial Guinea" },
  { value: "ER", label: "Eritrea" },
  { value: "EE", label: "Estonia" },
  { value: "ET", label: "Ethiopia" },
  { value: "FJ", label: "Fiji" },
  { value: "FI", label: "Finland" },
  { value: "FR", label: "France" },
  { value: "GA", label: "Gabon" },
  { value: "GM", label: "Gambia" },
  { value: "GE", label: "Georgia" },
  { value: "DE", label: "Germany" },
  { value: "GH", label: "Ghana" },
  { value: "GR", label: "Greece" },
  { value: "GD", label: "Grenada" },
  { value: "GT", label: "Guatemala" },
  { value: "GN", label: "Guinea" },
  { value: "GW", label: "Guinea-Bissau" },
  { value: "GY", label: "Guyana" },
  { value: "HT", label: "Haiti" },
  { value: "VA", label: "Holy See" },
  { value: "HN", label: "Honduras" },
  { value: "HU", label: "Hungary" },
  { value: "IS", label: "Iceland" },
  { value: "IN", label: "India" },
  { value: "ID", label: "Indonesia" },
  { value: "IR", label: "Iran" },
  { value: "IQ", label: "Iraq" },
  { value: "IE", label: "Ireland" },
  { value: "IL", label: "Israel" },
  { value: "IT", label: "Italy" },
  { value: "JM", label: "Jamaica" },
  { value: "JP", label: "Japan" },
  { value: "JO", label: "Jordan" },
  { value: "KZ", label: "Kazakhstan" },
  { value: "KE", label: "Kenya" },
  { value: "KI", label: "Kiribati" },
  { value: "KW", label: "Kuwait" },
  { value: "KG", label: "Kyrgyzstan" },
  { value: "LA", label: "Laos" },
  { value: "LV", label: "Latvia" },
  { value: "LB", label: "Lebanon" },
  { value: "LS", label: "Lesotho" },
  { value: "LR", label: "Liberia" },
  { value: "LY", label: "Libya" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Lithuania" },
  { value: "LU", label: "Luxembourg" },
  { value: "MG", label: "Madagascar" },
  { value: "MW", label: "Malawi" },
  { value: "MY", label: "Malaysia" },
  { value: "MV", label: "Maldives" },
  { value: "ML", label: "Mali" },
  { value: "MT", label: "Malta" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MR", label: "Mauritania" },
  { value: "MU", label: "Mauritius" },
  { value: "MX", label: "Mexico" },
  { value: "FM", label: "Micronesia" },
  { value: "MD", label: "Moldova" },
  { value: "MC", label: "Monaco" },
  { value: "MN", label: "Mongolia" },
  { value: "ME", label: "Montenegro" },
  { value: "MA", label: "Morocco" },
  { value: "MZ", label: "Mozambique" },
  { value: "MM", label: "Myanmar" },
  { value: "NA", label: "Namibia" },
  { value: "NR", label: "Nauru" },
  { value: "NP", label: "Nepal" },
  { value: "NL", label: "Netherlands" },
  { value: "NZ", label: "New Zealand" },
  { value: "NI", label: "Nicaragua" },
  { value: "NE", label: "Niger" },
  { value: "NG", label: "Nigeria" },
  { value: "KP", label: "North Korea" },
  { value: "MK", label: "North Macedonia" },
  { value: "NO", label: "Norway" },
  { value: "OM", label: "Oman" },
  { value: "PK", label: "Pakistan" },
  { value: "PW", label: "Palau" },
  { value: "PS", label: "Palestine State" },
  { value: "PA", label: "Panama" },
  { value: "PG", label: "Papua New Guinea" },
  { value: "PY", label: "Paraguay" },
  { value: "PE", label: "Peru" },
  { value: "PH", label: "Philippines" },
  { value: "PL", label: "Poland" },
  { value: "PT", label: "Portugal" },
  { value: "QA", label: "Qatar" },
  { value: "RO", label: "Romania" },
  { value: "RU", label: "Russia" },
  { value: "RW", label: "Rwanda" },
  { value: "KN", label: "Saint Kitts and Nevis" },
  { value: "LC", label: "Saint Lucia" },
  { value: "VC", label: "Saint Vincent and the Grenadines" },
  { value: "WS", label: "Samoa" },
  { value: "SM", label: "San Marino" },
  { value: "ST", label: "Sao Tome and Principe" },
  { value: "SA", label: "Saudi Arabia" },
  { value: "SN", label: "Senegal" },
  { value: "RS", label: "Serbia" },
  { value: "SC", label: "Seychelles" },
  { value: "SL", label: "Sierra Leone" },
  { value: "SG", label: "Singapore" },
  { value: "SK", label: "Slovakia" },
  { value: "SI", label: "Slovenia" },
  { value: "SB", label: "Solomon Islands" },
  { value: "SO", label: "Somalia" },
  { value: "ZA", label: "South Africa" },
  { value: "KR", label: "South Korea" },
  { value: "SS", label: "South Sudan" },
  { value: "ES", label: "Spain" },
  { value: "LK", label: "Sri Lanka" },
  { value: "SD", label: "Sudan" },
  { value: "SR", label: "Suriname" },
  { value: "SE", label: "Sweden" },
  { value: "CH", label: "Switzerland" },
  { value: "SY", label: "Syria" },
  { value: "TJ", label: "Tajikistan" },
  { value: "TZ", label: "Tanzania" },
  { value: "TH", label: "Thailand" },
  { value: "TL", label: "Timor-Leste" },
  { value: "TG", label: "Togo" },
  { value: "TO", label: "Tonga" },
  { value: "TT", label: "Trinidad and Tobago" },
  { value: "TN", label: "Tunisia" },
  { value: "TR", label: "Turkey" },
  { value: "TM", label: "Turkmenistan" },
  { value: "TV", label: "Tuvalu" },
  { value: "UG", label: "Uganda" },
  { value: "UA", label: "Ukraine" },
  { value: "AE", label: "United Arab Emirates" },
  { value: "UY", label: "Uruguay" },
  { value: "UZ", label: "Uzbekistan" },
  { value: "VU", label: "Vanuatu" },
  { value: "VE", label: "Venezuela" },
  { value: "VN", label: "Vietnam" },
  { value: "YE", label: "Yemen" },
  { value: "ZM", label: "Zambia" },
  { value: "ZW", label: "Zimbabwe" },
];

export enum SchedulingFrequency {
  FIVE_TIMES_PER_WEEK = "5x/week",
  FOUR_TIMES_PER_WEEK = "4x/week",
  THREE_TIMES_PER_WEEK = "3x/week",
  TWO_TIMES_PER_WEEK = "2x/week",
  ONE_TIME_PER_WEEK = "1x/week",
  TWO_TIMES_PER_MONTH = "2x per month",
  ONE_TIME_PER_MONTH = "1x per month",
  ONE_TIME_EVERY_TWO_MONTHS = "1x every 2 months",
  ONE_TIME_EVERY_THREE_MONTHS = "1x every 3 months",
}

const CURRENT_SCHEDULING_FREQUENCY_OPTIONS = [
  SchedulingFrequency.FIVE_TIMES_PER_WEEK,
  SchedulingFrequency.FOUR_TIMES_PER_WEEK,
  SchedulingFrequency.THREE_TIMES_PER_WEEK,
  SchedulingFrequency.TWO_TIMES_PER_WEEK,
  SchedulingFrequency.ONE_TIME_PER_WEEK,
  SchedulingFrequency.TWO_TIMES_PER_MONTH,
  SchedulingFrequency.ONE_TIME_PER_MONTH,
  SchedulingFrequency.ONE_TIME_EVERY_TWO_MONTHS,
  SchedulingFrequency.ONE_TIME_EVERY_THREE_MONTHS,
].map((frequency) => ({
  value: frequency,
  label: frequency,
}));

// TODO: Remove this once we have migrated all members to the new scheduling frequency options
// Used in loading past scheduling frequency option in appt note, so that we dont upload the old scheduling frequency option

export const LEGACY_SCHEDULING_FREQUENCY_OPTIONS = [
  {
    value: "3+/week",
    label: "3+/week [No longer used]",
    isDisabled: true,
  },
  {
    value: "2x/week (less than 120 minutes)",
    label: "2x/week (less than 120 minutes) [No longer used]",
    isDisabled: true,
  },
  {
    value: "1x /week (less than 60 minutes)",
    label: "1x /week (less than 60 minutes) [No longer used]",
    isDisabled: true,
  },
  {
    value: "Fewer than 1x/week",
    label: "Fewer than 1x/week [No longer used]",
    isDisabled: true,
  },
  {
    value: "Approximately 1x/every 3 months",
    label: "Approximately 1x/every 3 months [No longer used]",
    isDisabled: true,
  },
  {
    value: "As Needed / PRN",
    label: "As Needed / PRN [No longer used]",
    isDisabled: true,
  },
];

export const SCHEDULING_FREQUENCY_OPTIONS = [
  ...CURRENT_SCHEDULING_FREQUENCY_OPTIONS,
  ...LEGACY_SCHEDULING_FREQUENCY_OPTIONS,
];

export const NO_CLINICAL_RECOMMENDED_FREQUENCY_REASON =
  "No recommendation supplied. Deferring to the therapist's clinical judgment.";

enum BulkSchedulingGuidance {
  SCHEDULING_OUT = "Scheduling Out",
  ONE_SESSION_AT_A_TIME = "One Session at a Time",
  BULK_SCHEDULING_MULTIPLE_OUT = "Bulk Scheduling Multiple Out",
}

export const BULK_SCHEDULING_GUIDANCE_OPTIONS = [
  BulkSchedulingGuidance.SCHEDULING_OUT,
  BulkSchedulingGuidance.ONE_SESSION_AT_A_TIME,
  BulkSchedulingGuidance.BULK_SCHEDULING_MULTIPLE_OUT,
].map((option) => ({
  value: option,
  label: option,
}));

const ICD_CODE_MAPPING_CHILD: {
  [key: string]: string[];
} = {
  "F42.2": ["docs", "fas"],
  "F63.3": ["rbfbs"],
  "F42.4": ["rbfbs"],
  "F42.8": ["rbfbs"],
  "F95.2": ["tic"],
  "F95.1": ["tic"],
  "F95.0": ["tic"],
  "F95.8": ["tic"],
  "F42.3": ["hrs", "cir"],
  "F43.0": ["pcl5"],
  "F43.11": ["pcl5"],
  "F43.12": ["pcl5"],
  "F34.1": ["mfq"],
  "F33.2": ["mfq"],
  "F33.41": ["mfq"],
  "F33.0": ["mfq"],
  "F33.1": ["mfq"],
  "F33.3": ["mfq"],
  "F32.4": ["mfq"],
  "F32.0": ["mfq"],
  "F32.1": ["mfq"],
  "F32.3": ["mfq"],
  "F32.2": ["mfq"],
  "F06.31": ["mfq"],
  "F06.32": ["mfq"],
  "F32.8": ["mfq"],
  "F33.8": ["mfq"],
  "F32.89": ["mfq"],
  "F31.31": ["mfq"],
  "F31.30": ["mfq"],
  "F31.32": ["mfq"],
  "F31.5": ["mfq"],
  "F31.4": ["mfq"],
  "F31.0": ["mfq"],
  "F31.11": ["mfq"],
  "F31.12": ["mfq"],
  "F31.13": ["mfq"],
  "F31.10": ["mfq"],
  "F31.61": ["mfq"],
  "F31.62": ["mfq"],
  "F31.64": ["mfq"],
  "F31.63": ["mfq"],
  "F31.60": ["mfq"],
  "F31.75": ["mfq"],
  "F31.71": ["mfq"],
  "F31.73": ["mfq"],
  "F31.77": ["mfq"],
  "F34.0": ["mfq"],
  "F06.33": ["mfq"],
  "F06.34": ["mfq"],
  "F06.30": ["mfq"],
  "F31.89": ["mfq"],
  "F31.81": ["mfq"],
  "F40.11": ["lsas_ca", "fas"],
  "F41.0": ["asi_3", "fas"],
  "F40.01": ["asi_3", "fas"],
  "F40.02": ["smaa", "fas"],
  "F41.1": ["pswq_c", "fas"],
  "F40.218": ["smspa", "fas"],
  "F40.210": ["smspa", "fas"],
  "F40.230": ["smspa", "fas"],
  "F40.233": ["smspa", "fas"],
  "F40.290": ["smspa", "fas"],
  "F40.231": ["smspa", "fas"],
  "F40.228": ["smspa", "fas"],
  "F40.248": ["smspa", "fas"],
  "F40.242": ["smspa", "fas"],
  "F40.240": ["smspa", "fas"],
  "F40.243": ["smspa", "fas"],
  "F40.241": ["smspa", "fas"],
  "F40.298": ["smspa", "fas"],
  "F93.0": ["smsada", "fas"],
  "F45.21": ["shai", "fas"],
  "F45.0": ["shai", "fas"],
  "Z.1": ["docs", "fas"],
  "Z.2": ["rbfbs"],
  "Z.3": ["pswq_c", "fas"],
  "Z.4": ["asi_3", "fas"],
  "Z.5": ["lsas_ca", "fas"],
  "Z.6": ["smspa", "fas"],
  "Z.7": ["shai", "fas"],
  "Z.8": ["smaa", "fas"],
  "Z.9": ["smsada", "fas"],
  "Z.10": ["mfq"],
  "Z.12": ["hrs", "cir"],
  "Z.13": ["pcl5"],
  "Z.14": ["tic"],
};

const ICD_CODE_MAPPING_ADOLESCENT: {
  [key: string]: string[];
} = {
  "F42.2": ["docs", "fas"],
  "F63.3": ["rbfbs"],
  "F42.4": ["rbfbs"],
  "F42.8": ["rbfbs"],
  "F95.2": ["tic"],
  "F95.1": ["tic"],
  "F95.0": ["tic"],
  "F95.8": ["tic"],
  "F42.3": ["hrs", "cir"],
  "F43.0": ["pcl5"],
  "F43.11": ["pcl5"],
  "F43.12": ["pcl5"],
  "F34.1": ["phq_9"],
  "F33.2": ["phq_9"],
  "F33.41": ["phq_9"],
  "F33.0": ["phq_9"],
  "F33.1": ["phq_9"],
  "F33.3": ["phq_9"],
  "F32.4": ["phq_9"],
  "F32.0": ["phq_9"],
  "F32.1": ["phq_9"],
  "F32.3": ["phq_9"],
  "F32.2": ["phq_9"],
  "F06.31": ["phq_9"],
  "F06.32": ["phq_9"],
  "F32.8": ["phq_9"],
  "F33.8": ["phq_9"],
  "F32.89": ["phq_9"],
  "F31.31": ["phq_9"],
  "F31.30": ["phq_9"],
  "F31.32": ["phq_9"],
  "F31.5": ["phq_9"],
  "F31.4": ["phq_9"],
  "F31.0": ["phq_9"],
  "F31.11": ["phq_9"],
  "F31.12": ["phq_9"],
  "F31.13": ["phq_9"],
  "F31.10": ["phq_9"],
  "F31.61": ["phq_9"],
  "F31.62": ["phq_9"],
  "F31.64": ["phq_9"],
  "F31.63": ["phq_9"],
  "F31.60": ["phq_9"],
  "F31.75": ["phq_9"],
  "F31.71": ["phq_9"],
  "F31.73": ["phq_9"],
  "F31.77": ["phq_9"],
  "F34.0": ["phq_9"],
  "F06.33": ["phq_9"],
  "F06.34": ["phq_9"],
  "F06.30": ["phq_9"],
  "F31.89": ["phq_9"],
  "F31.81": ["phq_9"],
  "F40.11": ["lsas_ca", "fas"],
  "F41.0": ["asi_3", "fas"],
  "F40.01": ["asi_3", "fas"],
  "F40.02": ["smaa", "fas"],
  "F41.1": ["gad_7", "fas"],
  "F40.218": ["smspa", "fas"],
  "F40.210": ["smspa", "fas"],
  "F40.230": ["smspa", "fas"],
  "F40.233": ["smspa", "fas"],
  "F40.290": ["smspa", "fas"],
  "F40.231": ["smspa", "fas"],
  "F40.228": ["smspa", "fas"],
  "F40.248": ["smspa", "fas"],
  "F40.242": ["smspa", "fas"],
  "F40.240": ["smspa", "fas"],
  "F40.243": ["smspa", "fas"],
  "F40.241": ["smspa", "fas"],
  "F40.298": ["smspa", "fas"],
  "F93.0": ["smsada", "fas"],
  "F45.21": ["shai", "fas"],
  "F45.0": ["shai", "fas"],
  "F45.22": ["biq"],
  "Z.1": ["docs", "fas"],
  "Z.2": ["rbfbs"],
  "Z.3": ["gad_7", "fas"],
  "Z.4": ["asi_3", "fas"],
  "Z.5": ["lsas_ca", "fas"],
  "Z.6": ["smspa", "fas"],
  "Z.7": ["shai", "fas"],
  "Z.8": ["smaa", "fas"],
  "Z.9": ["smsada", "fas"],
  "Z.10": ["phq_9"],
  "Z.11": ["biq"],
  "Z.12": ["hrs", "cir"],
  "Z.13": ["pcl5"],
  "Z.14": ["tic"],
};

const ICD_CODE_MAPPING_ADULT: {
  [key: string]: string[];
} = {
  "F42.2": ["docs"],
  "F63.3": ["rbfbs"],
  "F42.4": ["rbfbs"],
  "F42.8": ["rbfbs"],
  "F95.2": ["tic"],
  "F95.1": ["tic"],
  "F95.0": ["tic"],
  "F95.8": ["tic"],
  "F42.3": ["hrs", "cir"],
  "F43.0": ["pcl5"],
  "F43.11": ["pcl5"],
  "F43.12": ["pcl5"],
  "F34.1": ["phq_9"],
  "F33.2": ["phq_9"],
  "F33.41": ["phq_9"],
  "F33.0": ["phq_9"],
  "F33.1": ["phq_9"],
  "F33.3": ["phq_9"],
  "F32.4": ["phq_9"],
  "F32.0": ["phq_9"],
  "F32.1": ["phq_9"],
  "F32.3": ["phq_9"],
  "F32.2": ["phq_9"],
  "F06.31": ["phq_9"],
  "F06.32": ["phq_9"],
  "F32.8": ["phq_9"],
  "F33.8": ["phq_9"],
  "F32.89": ["phq_9"],
  "F31.31": ["phq_9"],
  "F31.30": ["phq_9"],
  "F31.32": ["phq_9"],
  "F31.5": ["phq_9"],
  "F31.4": ["phq_9"],
  "F31.0": ["phq_9"],
  "F31.11": ["phq_9"],
  "F31.12": ["phq_9"],
  "F31.13": ["phq_9"],
  "F31.10": ["phq_9"],
  "F31.61": ["phq_9"],
  "F31.62": ["phq_9"],
  "F31.64": ["phq_9"],
  "F31.63": ["phq_9"],
  "F31.60": ["phq_9"],
  "F31.75": ["phq_9"],
  "F31.71": ["phq_9"],
  "F31.73": ["phq_9"],
  "F31.77": ["phq_9"],
  "F34.0": ["phq_9"],
  "F06.33": ["phq_9"],
  "F06.34": ["phq_9"],
  "F06.30": ["phq_9"],
  "F31.89": ["phq_9"],
  "F31.81": ["phq_9"],
  "F40.11": ["lsas"],
  "F41.0": ["asi_3"],
  "F40.01": ["asi_3"],
  "F40.02": ["smaa"],
  "F41.1": ["gad_7"],
  "F40.218": ["smspa"],
  "F40.210": ["smspa"],
  "F40.230": ["smspa"],
  "F40.233": ["smspa"],
  "F40.290": ["smspa"],
  "F40.231": ["smspa"],
  "F40.228": ["smspa"],
  "F40.248": ["smspa"],
  "F40.242": ["smspa"],
  "F40.240": ["smspa"],
  "F40.243": ["smspa"],
  "F40.241": ["smspa"],
  "F40.298": ["smspa"],
  "F93.0": ["smsada"],
  "F45.21": ["shai"],
  "F45.0": ["shai"],
  "F45.22": ["biq"],
  "Z.1": ["docs"],
  "Z.2": ["rbfbs"],
  "Z.3": ["gad_7"],
  "Z.4": ["asi_3"],
  "Z.5": ["lsas"],
  "Z.6": ["smspa"],
  "Z.7": ["shai"],
  "Z.8": ["smaa"],
  "Z.9": ["smsada"],
  "Z.10": ["phq_9"],
  "Z.11": ["biq"],
  "Z.12": ["hrs", "cir"],
  "Z.13": ["pcl5"],
  "Z.14": ["tic"],
};

export function getUniqueFormNamesBasedOnICDCodes(
  icdCodes: string[],
  protocolType: string
): string[] {
  let codeToFormMap = ICD_CODE_MAPPING_ADULT;

  if (protocolType === ProtocolTypes.CHILD) {
    codeToFormMap = ICD_CODE_MAPPING_CHILD;
  } else if (protocolType === ProtocolTypes.ADOLESCENT) {
    codeToFormMap = ICD_CODE_MAPPING_ADOLESCENT;
  }

  const uniqueForms = new Set<string>();

  icdCodes.forEach((code) => {
    // "F40.298 - Other Specified Phobia" => "F40.298"
    const parsedCode = code.split(" - ")[0];
    const forms = codeToFormMap[parsedCode];
    if (forms) {
      forms.forEach((form) => uniqueForms.add(form));
    }
  });

  return Array.from(uniqueForms);
}

export const formFrequencyMap = {
  hrs: 3,
  cir: 3,
  tic: 3,
  rbfbs: 3,
  pcl5: 3,
  docs: 3,
  pswq_c: 3,
  mfq: 3,
  fas: 3,
  biq: 3,
  lsas_ca: 3,
  lsas: 3,
  shai: 3,
  smsada: 3,
  smaa: 3,
  smspa: 3,
  asi_3: 3,
  gad_7: 3,
  phq_9: 3,
  dass_21: 6,
  qles: 6,
  whodas2: 12,
};

export const POST_ASSESSMENT_SUMMARY_DEFAULT_COMMENTS =
  "Hello, this document includes your clinical diagnosis based on my review of the assessments we've completed and a brief description of the treatment plan we'll follow. I would be happy to discuss this in session or via messaging if you have any questions.";
