import axios from "axios";
import { isEmpty } from "lodash/fp";
import { useQuery, UseQueryResult } from "react-query";

export const getQueryKey = (): string => "session";

export interface SessionUser {
  id: number;
  image: string | null;
  name: string | null;
  email: string | null;
}

export interface Session {
  expires: string;
  user: SessionUser;
  accessToken: string;
}

const fetchSession = async (): Promise<Session | null> => {
  const response = await axios.get<Session>("/api/auth/session");
  const { data: sessionData, status } = response;

  if (status >= 400 || isEmpty(sessionData)) {
    return null;
  }

  return sessionData;
};

export const useSession = (): UseQueryResult<Session, Error> =>
  useQuery(getQueryKey(), fetchSession, {
    notifyOnChangeProps: ["data", "error"],
  });
