import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { ClinicianAssociate } from "@core/services/nocd-api/clinicians";
import { useQuery, UseQueryOptions } from "react-query";

const getSupervisorAssociates = (accessToken: string, signal?: AbortSignal) =>
  api
    .get<ClinicianAssociate[]>(`/v1/clinicians/supervisor_associates`, {
      headers: {
        Authorization: accessToken,
      },
      signal,
    })
    .then(({ data }) => data)
    .catch(transformAPIError);

export const useSupervisorAssociates = <T = ClinicianAssociate[]>(
  options?: UseQueryOptions<ClinicianAssociate[], Error, T>
) => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    ["supervisor-associates", session?.accessToken],
    ({ signal }) => getSupervisorAssociates(accessToken, signal),
    {
      enabled: !!accessToken,
      ...(options ?? {}),
    }
  );
};
