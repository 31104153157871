import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useQuery, UseQueryResult } from "react-query";

export interface UnreadsInChannel {
  unreads_in_channel: number;
  chat_channel_id: number;
}

export interface WebNotifications {
  web_alert_new_message: UnreadsInChannel[];
  web_alert_24_hours_waiting: UnreadsInChannel[];
  web_alert_48_hours_waiting: UnreadsInChannel[];
}

export const useWebNotifications = (): UseQueryResult<
  WebNotifications,
  Error
> => {
  const { enableNotoWebMessagingNotifications } = useFlags();
  const { data: session } = useSession();
  const accessToken = session?.accessToken;

  return useQuery(
    `web-notifications-${accessToken}`,
    async ({ signal }) => {
      return api
        .get<WebNotifications>(`/v3/clinician/chat/web_notifications`, {
          headers: {
            authorization: accessToken,
          },
          signal,
        })
        .then((res) => {
          const { data } = res;
          return data;
        })
        .catch(transformAPIError);
    },
    {
      enabled:
        !!accessToken && (enableNotoWebMessagingNotifications as boolean),
      refetchInterval: 60 * 1000, // 60 seconds
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
    }
  );
};
