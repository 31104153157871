import { useSession } from "@core/hooks/useSession";
import { AxiosError } from "axios";
import { isNil } from "lodash/fp";
import { useMutation } from "react-query";
import useSWR, { SWRResponse } from "swr";
import invariant from "tiny-invariant";

import api from "./api";
import {
  APIError,
  AWSFileContent,
  Member,
  MemberABNMedicare,
  MemberAssessmentResults,
  MemberCareTeamAlert,
  MemberCareTeamInfo,
  MemberClinicianNotes,
  MemberCompulsion,
  MemberContactNote,
  MemberDischargeNote,
  MemberExposure,
  MemberFormAutomationActive,
  MemberFormAutomationSettings,
  MemberImage,
  MemberLink,
  MemberLooptape,
  MemberNOCD411Consent,
  MemberNoticeOfPrivacyPractices,
  MemberObsession,
  MemberOCICVRConsentForm,
  MemberPastAppointmentNotes,
  MemberRPM,
  MemberScript,
  MembersRelease,
  MembersSubtypes,
  MemberTherapyConsent,
  MemberTrigger,
  SafetyPlan,
} from "./types";

export const updateMember = (
  userId: number | string,
  payload: Partial<Member>,
  accessToken: string | undefined
): Promise<Member> =>
  api
    .post<Member>(
      `/v2/clinician/members/${encodeURIComponent(userId)}`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const useUpdateMember = () => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useMutation((payload: Partial<Member>) =>
    updateMember(payload.userID, payload, accessToken)
  );
};

export const getMember = (
  userId: number | string | undefined,
  accessToken: string | undefined
): Promise<Member> => {
  invariant(userId != null, "userId is required");

  return api
    .get<Member>(
      `/v2/clinician/members/${encodeURIComponent(userId)}`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });
};

export const getMemberQueryKey = (
  userId: string | number | undefined,
  accessToken: string | undefined
) =>
  !isNil(accessToken) && !isNil(userId)
    ? ["member", userId, accessToken]
    : undefined;

// ONLY Therapist can use this hook
// Scheduling/Intake team can not use this hook
// it has role restrictions in the backend so it will break if used by scheduling/intake team
export const useMember = (
  userId: number | string | undefined
): SWRResponse<Member, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const swrProps = useSWR<Member, Error>(
    userId ? getMemberQueryKey(userId, accessToken) : null,
    () => getMember(userId, accessToken)
  );

  return swrProps;
};

export const updateMemberSafetyPlan = (
  userId: number | string,
  payload: SafetyPlan,
  accessToken: string | undefined
): Promise<SafetyPlan> =>
  api
    .post<SafetyPlan>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/safety_plan`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const getMemberObsessions = (
  userId: number | string,
  accessToken: string | undefined
): Promise<MemberObsession[]> =>
  api
    .get<MemberObsession[]>(
      `/v2/clinician/members/${encodeURIComponent(
        userId
      )}/hierarchy/obsessions`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const getMemberCompulsions = (
  userId: number | string,
  accessToken: string | undefined
): Promise<MemberCompulsion[]> =>
  api
    .get<MemberCompulsion[]>(
      `/v2/clinician/members/${encodeURIComponent(
        userId
      )}/hierarchy/compulsions`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const getMemberTriggers = (
  userId: number | string,
  accessToken: string | undefined
): Promise<MemberTrigger[]> =>
  api
    .get<MemberTrigger[]>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/hierarchy/triggers`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const getMemberExposures = (
  userId: number | string,
  accessToken: string | undefined
): Promise<MemberExposure[]> =>
  api
    .get<MemberExposure[]>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/hierarchy/exposures`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const getMemberRPMs = (
  userId: number | string,
  accessToken: string | undefined
): Promise<MemberRPM[]> =>
  api
    .get<MemberRPM[]>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/hierarchy/rpms`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const getMemberScripts = (
  userId: string,
  accessToken: string | undefined
): Promise<MemberScript[]> =>
  api
    .get<MemberScript[]>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/hierarchy/scripts`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const getMemberLinks = (
  userId: string,
  accessToken: string | undefined
): Promise<MemberLink[]> =>
  api
    .get<MemberLink[]>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/hierarchy/links`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const getMemberLooptapes = (
  userId: string,
  accessToken: string | undefined
): Promise<MemberLooptape[]> =>
  api
    .get<MemberLooptape[]>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/hierarchy/looptapes`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const getMemberImages = (
  userId: string,
  accessToken: string | undefined
): Promise<MemberImage[]> =>
  api
    .get<MemberImage[]>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/hierarchy/images`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

const filterEntitiesByObsessionId = <T extends { obsessionID: string }>(
  entities: T[],
  obsessionId: string | undefined
): T[] => {
  if (!obsessionId) {
    return entities;
  }

  return entities.filter((entity) => entity.obsessionID === obsessionId);
};

interface UseMemberHierarchyData {
  obsessions: MemberObsession[];
  compulsions: MemberCompulsion[];
  triggers: MemberTrigger[];
  exposures: MemberExposure[];
  rpms: MemberRPM[];
  scripts: MemberScript[];
  links: MemberLink[];
  looptapes: MemberLooptape[];
  images: MemberImage[];
}

export function useMemberHierarchy(
  userId: string,
  obsessionId: string | undefined
) {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const { data, mutate } = useSWR<Partial<UseMemberHierarchyData>>(
    !isNil(userId) && !isNil(accessToken)
      ? ["member-hierarchy", userId, accessToken]
      : undefined,
    () =>
      Promise.all([
        getMemberObsessions(userId, accessToken),
        getMemberCompulsions(userId, accessToken),
        getMemberTriggers(userId, accessToken),
        getMemberExposures(userId, accessToken),
        getMemberRPMs(userId, accessToken),
        getMemberScripts(userId, accessToken),
        getMemberLinks(userId, accessToken),
        getMemberLooptapes(userId, accessToken),
        getMemberImages(userId, accessToken),
      ]).then(
        ([
          obsessions,
          compulsions,
          triggers,
          exposures,
          rpms,
          scripts,
          links,
          looptapes,
          images,
        ]) => ({
          obsessions,
          compulsions,
          triggers,
          exposures,
          rpms,
          scripts,
          links,
          looptapes,
          images,
        })
      )
  );

  const mutateWithPartialUpdate = (newData: Partial<UseMemberHierarchyData>) =>
    mutate({
      ...data,
      ...newData,
    });

  return {
    data: data
      ? {
          obsessions: data.obsessions,
          compulsions: filterEntitiesByObsessionId(
            data?.compulsions ?? [],
            obsessionId
          ),
          triggers: filterEntitiesByObsessionId(
            data?.triggers ?? [],
            obsessionId
          ),
          exposures: filterEntitiesByObsessionId(
            data?.exposures ?? [],
            obsessionId
          ),
          rpms: filterEntitiesByObsessionId(data?.rpms ?? [], obsessionId),
          scripts: data.scripts,
          links: data.links,
          looptapes: data.looptapes,
          images: data.images,
        }
      : undefined,
    mutate: mutateWithPartialUpdate,
  };
}

export const updateMemberContactNote = (
  userId: number | string,
  payload: MemberContactNote,
  accessToken: string | undefined
): Promise<Member> =>
  api
    .post<Member>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/contact_notes`,
      {
        ...payload,
        contact_at: payload.contact_at
          ? new Date(payload.contact_at).toISOString()
          : undefined,
        action: payload.id ? "DELETE" : "ADD",
      },
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const addMemberDischargeNote = (
  userId: number | string,
  payload: Partial<MemberDischargeNote>,
  accessToken: string | undefined,
  isUpdate: boolean,
  enableDischargeEmail: boolean,
  existingDischargeNoteId?: number | string | null
): Promise<Member> =>
  api
    .post<Member>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/discharge_notes`,
      {
        ...payload,
        discharge_at: payload.discharge_at
          ? new Date(payload.discharge_at).toISOString()
          : undefined,
        is_update: isUpdate,
        existing_discharge_note_id: existingDischargeNoteId,
        is_discharge_email_enabled: enableDischargeEmail,
      },
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const getMemberReleaseInformation = (
  userId: number | string | undefined,
  accessToken: string | undefined
): Promise<MembersRelease> => {
  invariant(userId != null, "userId is required");

  return api
    .get<MembersRelease>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/release_information`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => {
      return data;
    })
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });
};

export const getMemberTherapyConsentForm = (
  userId: number | string | undefined,
  accessToken: string | undefined
): Promise<MemberTherapyConsent> => {
  invariant(userId != null, "userId is required");

  return api
    .get<MemberTherapyConsent>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/therapy_consent`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });
};

export const getMemberTherapyConsentFormList = (
  userId: number | string | undefined,
  accessToken: string | undefined
): Promise<MemberTherapyConsent[]> => {
  invariant(userId != null, "userId is required");
  return api
    .get<MemberTherapyConsent[]>(
      `/v2/clinician/members/${encodeURIComponent(
        userId
      )}/therapy_consent_list`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });
};

export const getMemberNOCD411ConsentForm = (
  userId: number | string | undefined,
  accessToken: string | undefined
): Promise<MemberNOCD411Consent> => {
  invariant(userId != null, "userId is required");

  return api
    .get<MemberNOCD411Consent>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/nocd411_consent`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });
};

export const getMemberNoticeOfPrivacyPracticesForm = (
  userId: number | string | undefined,
  accessToken: string | undefined
): Promise<MemberNoticeOfPrivacyPractices[]> => {
  invariant(userId != null, "userId is required");

  return api
    .get<MemberNoticeOfPrivacyPractices[]>(
      `/v2/clinician/members/${encodeURIComponent(
        userId
      )}/v2/notice_of_privacy_practices`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });
};

export const getMemberOCICVRConsentForm = (
  userId: number | string | undefined,
  accessToken: string | undefined
): Promise<MemberOCICVRConsentForm> => {
  invariant(userId != null, "userId is required");

  return api
    .get<MemberOCICVRConsentForm>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/ocicvr_consent_form`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });
};

export const getMemberSubtypes = (
  userId: number | string | undefined,
  accessToken: string | undefined
): Promise<MembersSubtypes> => {
  invariant(userId != null, "userId is required");

  return api
    .get<MembersSubtypes>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/subtypes`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });
};

export const getMemberClinicianNotes = (
  userId: number | string | undefined,
  accessToken: string | undefined
): Promise<MemberClinicianNotes> => {
  invariant(userId != null, "userId is required");

  return api
    .get<MemberClinicianNotes>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/clinician_notes`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });
};

export interface UseMemberProfileData {
  releaseInformation?: MembersRelease;
  therapyConsentForm?: MemberTherapyConsent;
  nocd411ConsentForm?: MemberNOCD411Consent;
  therapyConsentFormList?: MemberTherapyConsent[];
  noticeOfPrivacyPracticesForm?: MemberNoticeOfPrivacyPractices[];
  ocicvrConsentForm?: MemberOCICVRConsentForm;
  subtypes?: MembersSubtypes;
  clinicianNotes?: MemberClinicianNotes;
  abnConsentForm?: MemberABNMedicare[];
}

export function useMemberProfile(userId: number | string | undefined): {
  data: UseMemberProfileData | undefined;
  mutate: (newData: UseMemberProfileData) => void;
} {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  const { data, mutate } = useSWR<UseMemberProfileData>(
    userId != null ? `member-profile-${userId}` : undefined,
    () =>
      Promise.all([
        getMemberReleaseInformation(userId, accessToken),
        getMemberTherapyConsentForm(userId, accessToken),
        getMemberTherapyConsentFormList(userId, accessToken),
        getMemberNOCD411ConsentForm(userId, accessToken),
        getMemberNoticeOfPrivacyPracticesForm(userId, accessToken),
        getMemberSubtypes(userId, accessToken),
        getMemberClinicianNotes(userId, accessToken),
        getMemberOCICVRConsentForm(userId, accessToken),
      ]).then(
        ([
          releaseInformation,
          therapyConsentForm,
          therapyConsentFormList,
          nocd411ConsentForm,
          noticeOfPrivacyPracticesForm,
          subtypes,
          clinicianNotes,
          ocicvrConsentForm,
        ]) => ({
          releaseInformation,
          therapyConsentForm,
          therapyConsentFormList,
          nocd411ConsentForm,
          noticeOfPrivacyPracticesForm,
          subtypes,
          clinicianNotes,
          ocicvrConsentForm,
        })
      )
  );

  const mutateWithPartialUpdate = (newData: UseMemberProfileData) =>
    mutate({
      ...data,
      ...newData,
    });

  return {
    data: data
      ? {
          releaseInformation: data.releaseInformation,
          therapyConsentForm: data.therapyConsentForm,
          therapyConsentFormList: data.therapyConsentFormList,
          nocd411ConsentForm: data.nocd411ConsentForm,
          noticeOfPrivacyPracticesForm: data.noticeOfPrivacyPracticesForm,
          subtypes: data.subtypes,
          clinicianNotes: data.clinicianNotes,
          ocicvrConsentForm: data.ocicvrConsentForm,
        }
      : undefined,
    mutate: mutateWithPartialUpdate,
  };
}

export const getMemberDocuments = (
  userId: string | number,
  directory: string,
  accessToken: string | undefined
): Promise<AWSFileContent[]> =>
  api
    .get<AWSFileContent[]>(`v2/clinician/members/${userId}/files`, {
      headers: accessToken
        ? {
            Authorization: accessToken,
          }
        : undefined,
      params: {
        directory,
      },
    })
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const getMemberDocumentsQueryKey = (
  userId: string | number,
  accessToken: string | undefined
) => {
  return !isNil(userId) && !isNil(accessToken)
    ? ["clinical-files", userId, accessToken]
    : undefined;
};

export const useMemberDocuments = (userId: string | number) => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  const swrProps = useSWR<AWSFileContent[]>(
    getMemberDocumentsQueryKey(userId, accessToken),
    () => getMemberDocuments(userId, "Clinical", accessToken)
  );

  return swrProps;
};

export const getMemberPastAppointmentsNotes = (
  userId: number | string,
  accessToken: string | undefined
): Promise<MemberPastAppointmentNotes[]> =>
  api
    .get<MemberPastAppointmentNotes[]>(
      `/v2/clinician/members/${encodeURIComponent(
        userId
      )}/past_appointments_notes`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const sendAlertPeerAdvisor = (
  userID: number | string,
  payload: MemberCareTeamAlert,
  accessToken: string | undefined
): Promise<Member> =>
  api
    .post<Member>(
      `/v2/clinician/members/${encodeURIComponent(
        userID
      )}/peer-advisor/flag-member`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const getMemberCareTeamInfo = (
  userId: number | string,
  accessToken: string | undefined
): Promise<MemberCareTeamInfo> =>
  api
    .get<MemberCareTeamInfo>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/care_team_info`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const getMemberAssessmentResultsLegacy = (
  accessToken: string | undefined,
  userId: number | string,
  appointmentId?: number | string
): Promise<MemberAssessmentResults> =>
  api
    .get<MemberAssessmentResults>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/assessment_results`,
      {
        params: {
          appointmentId,
        },
        headers: accessToken
          ? {
              Authorization: accessToken,
            }
          : undefined,
      }
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const getMemberAssessmentResults = (
  userId: number | string,
  accessToken: string | undefined
): Promise<MemberAssessmentResults> =>
  api
    .get<MemberAssessmentResults>(
      `/v2/clinician/members/${encodeURIComponent(
        userId
      )}/v2/assessment_results`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const getMemberFormAutomationSettings = (
  userId: number,
  accessToken: string | undefined
): Promise<MemberFormAutomationSettings> =>
  api
    .get<MemberFormAutomationSettings>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/form_automation`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const postFormAutomationActive = (
  userId: number,
  formAutomationActive: number,
  accessToken: string | undefined
): Promise<MemberFormAutomationActive> =>
  api
    .post<MemberFormAutomationActive>(
      `/v2/clinician/members/${encodeURIComponent(
        userId
      )}/form_automation/form_automation_active`,
      {
        form_automation_active: formAutomationActive,
      },
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const postFormAutomationForms = (
  userId: string | number,
  formNames: string[],
  formFrequency: number,
  accessToken: string | undefined
): Promise<MemberFormAutomationActive> =>
  api
    .post<MemberFormAutomationActive>(
      `/v2/clinician/members/${encodeURIComponent(
        userId
      )}/form_automation/forms`,
      {
        next_form_automation_form_names: formNames,
        form_automation_frequency: formFrequency,
      },
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const useAssessmentResults = (userId: string | number) => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  const swrProps = useSWR(
    !isNil(userId) && !isNil(accessToken)
      ? ["assessment_results", userId, accessToken]
      : undefined,
    () => getMemberAssessmentResults(userId, accessToken)
  );

  return swrProps;
};
