import { AxiosError } from "axios";

// You can add your own custom errors here and then map them to specific
// messages on the client.
export enum APIErrorType {
  UNKNOWN = "Unknown",
}

export enum APIErrorTypeV3 {
  VALIDATION = "validation",
  UNAUTHORIZED = "unauthorized",
  FORBIDDEN = "forbidden",
  BAD_REQUEST = "bad_request",
  UNKNOWN = "unknown",
  INVALID_GOOGLE_AUTH = "InvalidGoogleAuth",
}

export type APIErrorResponse = AxiosError<{
  error_short_name: APIErrorType | string;
  message: string;
}>;

export type DefaultResponse = { status: "ok" };

// Type guard to narrow the unknown error to an Axios error
export const isAxiosError = (error: unknown): error is AxiosError => {
  return (error as AxiosError).isAxiosError === true;
};

// The NOCD API returns errors in the following shape:
// {
//   error_short_name: "Unknown",
//   message: "An unknown error occurred."
// }
export const isApiError = (error: unknown): error is APIErrorResponse => {
  if (isAxiosError(error)) {
    const errorName = (error as APIErrorResponse).response?.data
      ?.error_short_name;
    const errorMessage = (error as APIErrorResponse).response?.data?.message;

    if (typeof errorName === "string" && typeof errorMessage === "string") {
      return true;
    }
  }
  return false;
};

type ApiV3ErrorResponse = AxiosError<{
  message: string;
  error_type: APIErrorTypeV3;
  code: number;
  errors?: Array<{
    message: string;
    path: string;
    error_type: string;
    context?: unknown;
  }>;
}>;

export const isApiV3Error = (error: unknown): error is ApiV3ErrorResponse => {
  if (isAxiosError(error)) {
    const tmp = error as ApiV3ErrorResponse;
    const data = tmp.response?.data;
    return (
      data &&
      typeof data.error_type === "string" &&
      typeof data.message === "string"
    );
  }
  return false;
};

// Normalize the error response to a consistent shape and re-throw it
export const transformAPIError = (error: APIErrorResponse | Error): never => {
  if (isApiV3Error(error)) {
    const transformedError = error;
    transformedError.name =
      error?.response?.data?.error_type ?? APIErrorTypeV3.UNKNOWN;
    transformedError.message =
      error?.response?.data?.message ?? "Unknown error";
    transformedError.stack = error.stack;
    throw transformedError;
  }

  if (isApiError(error)) {
    const transformedError = error;
    transformedError.message = error?.response?.data.message ?? "Unknown error";
    transformedError.name =
      error?.response?.data.error_short_name ?? APIErrorType.UNKNOWN;
    // Ensure that the stacktrace isn't lost
    transformedError.stack = error.stack;

    throw transformedError;
  }

  throw error;
};

export interface BadRequestError {
  code: 400;
  error_type: "bad_request";
  message: string;
}

export const isBadRequestError = (
  error: unknown
): error is AxiosError<BadRequestError> => {
  return (
    isAxiosError(error) &&
    (error as AxiosError<BadRequestError>).response?.data?.error_type ===
      "bad_request"
  );
};
