import { useSession } from "@core/hooks/useSession";
import {
  api,
  AssociateClinicianMember,
  transformAPIError,
} from "@core/services/nocd-api";
import { useQuery, UseQueryResult } from "react-query";

const getAssociatesMembers = (accessToken: string, signal?: AbortSignal) =>
  api
    .get<AssociateClinicianMember[]>(
      `/v1/clinicians/supervisor_associates_members`,
      {
        headers: {
          Authorization: accessToken,
        },
        signal,
      }
    )
    .then(({ data }) => data)
    .catch(transformAPIError);

export const useAssociatesMembers = (): UseQueryResult<
  AssociateClinicianMember[],
  Error
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    ["associates-members", session?.accessToken],
    ({ signal }) => getAssociatesMembers(accessToken, signal),
    {
      enabled: !!accessToken,
    }
  );
};
