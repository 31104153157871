import { useSession } from "@core/hooks/useSession";
import {
  api,
  DefaultResponse,
  transformAPIError,
} from "@core/services/nocd-api";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import invariant from "tiny-invariant";

interface AssignSpecialtyForTrainingPayload {
  user_id: number;
  is_associate_ready_for_clinical_specialty: boolean;
  assigned_specialties_for_training: string[] | null;
}

const getSupervisorAssociatesQueryKey = (
  accessToken: string
): [string, string] => ["supervisor-associates", accessToken];

const assignSpecialtyForTraining = (
  payload: AssignSpecialtyForTrainingPayload,
  accessToken: string | undefined
) => {
  invariant(payload.user_id != null, "associate user_id is required");
  return api
    .post<DefaultResponse>(
      `/v1/clinicians/supervisor_associates/assign_specialty_for_training`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);
};

export const useAssignSpecialtyForTraining = (): UseMutationResult<
  DefaultResponse,
  Error,
  AssignSpecialtyForTrainingPayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => assignSpecialtyForTraining(payload, accessToken),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(
          getSupervisorAssociatesQueryKey(accessToken)
        ),
    }
  );
};
