import ErrorScreen from "../layout/ErrorScreen";
import Loading from "./Loading";

export default function AccessDenied({ isLoading }: { isLoading?: boolean }) {
  if (isLoading) {
    return <Loading />;
  }

  return <ErrorScreen message="You do not have access to this feature." />;
}
