import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import { WebNotifications } from "../hooks/useWebNotifications";

type AlertType =
  | "web_alert_new_message"
  | "web_alert_24_hours_waiting"
  | "web_alert_48_hours_waiting";

const dismissWebNotification = (alert_type: AlertType, accessToken: string) =>
  api
    .post<{ dismissed: string }>(
      `/v3/clinician/chat/web_notifications`,
      {
        alert_type,
      },
      {
        headers: {
          Authorization: accessToken,
        },
      }
    )
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch(transformAPIError);

export const useDismissWebNotification = (): UseMutationResult<
  { dismissed: string },
  Error,
  { alertType: AlertType }
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation(
    ({ alertType }: { alertType: AlertType }) => {
      return dismissWebNotification(alertType, accessToken);
    },
    {
      onMutate: async ({ alertType }) => {
        await queryClient.cancelQueries({
          queryKey: [`web-notifications-${accessToken}`],
        });

        await queryClient.invalidateQueries({
          queryKey: `web-notifications-${accessToken}`,
        });

        const previousNotifications = queryClient.getQueryData([
          `web-notifications-${accessToken}`,
        ]);

        // Optimistically update to the new value
        queryClient.setQueryData(
          [`web-notifications-${accessToken}`],
          (old: WebNotifications) => ({
            ...old,
            [alertType]: [],
          })
        );

        // Return a context object with the snapshotted value
        return { previousNotifications };
      },

      onSuccess: (_data, _variables) => {
        return queryClient.invalidateQueries({
          queryKey: `web-notifications-${accessToken}`,
        });
      },
    }
  );
};
