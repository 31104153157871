import axios, { AxiosRequestConfig } from "axios";
import { v4 as uuidv4 } from "uuid";

const addLookupHeader = (config: AxiosRequestConfig) => {
  // eslint-disable-next-line no-param-reassign
  config.headers ??= {};
  // eslint-disable-next-line no-param-reassign
  config.headers["x-lookup-id"] = uuidv4();
  return config;
};

const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_NOCD_API_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  // 30 seconds
  timeout: 60 * 1000,
});
api.interceptors.request.use(addLookupHeader);

const apiModels = axios.create({
  baseURL: process.env.NEXT_PUBLIC_NOCD_MODELS_API_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  // 30 seconds
  timeout: 60 * 1000,
});
apiModels.interceptors.request.use(addLookupHeader);

export default api;
export { apiModels };
// export default api;
