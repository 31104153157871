import ErrorIcon from "@mui/icons-material/Error";
import { Box, Paper, Typography } from "@mui/material";

export default function ErrorScreen({ message }: { message?: string }) {
  return (
    <Box
      component={Paper}
      px={3}
      py={6}
      maxWidth={800}
      marginX="auto"
      textAlign="center"
    >
      <ErrorIcon color="error" fontSize="large" />
      <Typography variant="h6">
        {message ?? "An unknown error occurred."}
      </Typography>
    </Box>
  );
}
