import { useSession } from "@core/hooks/useSession";
import * as Sentry from "@sentry/nextjs";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { isNil } from "lodash/fp";
import LogRocket from "logrocket";
import { useEffect } from "react";

const SentryUserWatcher = () => {
  const { data } = useSession();
  const user = data?.user;
  const email = user?.email;
  const userId = user?.id;

  useEffect(() => {
    if (email && !isNil(userId) && process.env.NODE_ENV === "production") {
      console.log("Identifying Sentry user...");
      void Sentry.setUser({
        email,
        id: String(userId),
      });
    }
  }, [email, userId]);

  return null;
};

const LogRocketUserWatcher = () => {
  const { data } = useSession();
  const user = data?.user;
  const email = user?.email;
  const userId = user?.id;

  useEffect(() => {
    if (
      email &&
      !isNil(userId) &&
      process.env.NEXT_PUBLIC_LOGROCKET_PROJECT_ID &&
      process.env.NODE_ENV === "production"
    ) {
      console.log("Identifying LogRocket user...");
      void LogRocket.identify(`${userId}`, {
        email,
        gitCommitSHA:
          process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA ||
          "unknown_git_commit_sha",
      });
    }
  }, [email, userId]);

  return null;
};

const LaunchDarklyUserWatcher = () => {
  const launchDarklyClient = useLDClient();

  const { data: session } = useSession();
  const { user } = session ?? {};
  const { id, email, image, name } = user ?? {};

  useEffect(() => {
    if (email && launchDarklyClient) {
      console.log("Identifying LaunchDarkly user...");
      void launchDarklyClient.identify({
        key: email,
        email,
        name: name || undefined,
        avatar: image || undefined,
      });
    }
  }, [id, email, name, image, launchDarklyClient]);

  return null;
};

export default function SideEffects() {
  const { data: session } = useSession();
  const isAuthenticated = !!session?.accessToken;

  // This seems redundant, but it's necessary to ensure the LD client has
  // been initialized before we try to identify the user.
  const launchDarklyClient = useLDClient();
  const isLaunchDarklyInitialized = !!launchDarklyClient;

  return (
    <>
      {isAuthenticated && <LogRocketUserWatcher key="lr-user-watcher" />}
      {isAuthenticated && isLaunchDarklyInitialized && (
        <LaunchDarklyUserWatcher key="ld-user-watcher" />
      )}
      {isAuthenticated && <SentryUserWatcher key="sentry-user-watcher" />}
    </>
  );
}
