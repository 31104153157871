import { EhrRoles } from "@core/constants";
import { useSession } from "@core/hooks/useSession";
import { APIErrorTypeV3, useUser } from "@core/services/nocd-api";
import useMembersNotAssignedToRecommendedProtocol from "@core/services/nocd-api/clinicians/queries/useMembersNotAssignedToRecommendedProtocol";
import AccessDenied from "@core/ui/AccessDenied";
import useSidebarWidth from "@core/utils/DrawerWidthContext";
import {
  AssignmentOutlined as AssignmentOutlinedIcon,
  AttachMoneyOutlined as AttachMoneyOutlinedIcon,
  BadgeOutlined as BadgeOutlinedIcon,
  CalendarMonthOutlined as CalendarMonthOutlinedIcon,
  CalendarTodayOutlined as CalendarTodayOutlinedIcon,
  CallReceivedOutlined as CallReceivedOutlinedIcon,
  ChangeCircleOutlined as ChangeCircleOutlinedIcon,
  ChatOutlined as ChatOutlinedIcon,
  ChecklistOutlined as ChecklistOutlinedIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  CompareArrowsOutlined as CompareArrowsOutlinedIcon,
  CoPresentOutlined as CoPresentOutlinedIcon,
  DashboardOutlined as DashboardOutlinedIcon,
  DescriptionOutlined as DescriptionOutlinedIcon,
  ForumOutlined as ForumOutlinedIcon,
  GroupsOutlined as GroupsOutlinedIcon,
  HeadsetMicOutlined as HeadsetMicOutlinedIcon,
  HomeOutlined as HomeOutlinedIcon,
  HourglassEmptyOutlined as HourglassEmptyOutlinedIcon,
  LibraryBooksOutlined as LibraryBooksOutlinedIcon,
  MedicalServicesOutlined as MedicalServicesOutlinedIcon,
  MonitorHeartOutlined as MonitorHeartOutlinedIcon,
  PersonSearchOutlined as PersonSearchOutlinedIcon,
  RepeatOutlined as RepeatOutlinedIcon,
  ScheduleOutlined as ScheduleOutlinedIcon,
  SearchOutlined as SearchOutlinedIcon,
  StarOutlineOutlined as StarOutlineOutlinedIcon,
  StarsOutlined as StarsOutlinedIcon,
  TrendingUpOutlined as TrendingUpOutlinedIcon,
  ViewListOutlined as ViewListOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
} from "@mui/icons-material";
import {
  AppBar,
  Badge,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  Toolbar,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { useFlags } from "launchdarkly-react-client-sdk";
import { intersection, isEmpty } from "lodash";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { signIn } from "next-auth/react";
import ProBadge from "public/images/pro-badge.svg";
import { useCallback, useEffect, useState } from "react";

import NotAuthenticated from "./NotAuthenticated";
import SideEffects from "./SideEffects";
import UserMenu from "./UserMenu";

const useNavigationDrawer = () => {
  const { sidebarWidth, setSidebarWidth, isOpen, toggleSidebar } =
    useSidebarWidth();
  const [isNavigationDrawerOpen, setIsNavigationDrawerOpen] = useState(isOpen);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const isMinimized = smallScreen || !isNavigationDrawerOpen;

  const openNavigationDrawer = useCallback(() => {
    setIsNavigationDrawerOpen(true);
    setSidebarWidth(300);
    if (!isOpen) {
      toggleSidebar();
    }
  }, [setSidebarWidth, isOpen, toggleSidebar]);

  const closeNavigationDrawer = useCallback(() => {
    setIsNavigationDrawerOpen(false);
    setSidebarWidth(76);
    if (isOpen) {
      toggleSidebar();
    }
  }, [setSidebarWidth, isOpen, toggleSidebar]);

  useEffect(() => {
    const width = smallScreen || !isOpen ? 76 : 300;
    setSidebarWidth(width);
  }, [smallScreen, isOpen, setSidebarWidth]);

  useEffect(() => {
    setIsNavigationDrawerOpen(isOpen);
  }, [isOpen]);

  return {
    openNavigationDrawer,
    closeNavigationDrawer,
    isNavigationDrawerOpen,
    isMinimized,
    smallScreen,
    sidebarWidth,
  };
};

interface StyleProps {
  sidebarWidth: number;
  isMinimized: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  email: {
    color: "#3c92dc",
    cursor: "pointer",
    marginLeft: theme.spacing(1),
  },
  title: {
    fontWeight: "bold",
    flexGrow: 1,
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  root: {
    display: "flex",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawer: {
    zIndex: theme.zIndex.drawer + 2,
    width: ({ sidebarWidth }) => sidebarWidth,
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerPaper: {
    width: ({ sidebarWidth }) => sidebarWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawerContainer: {
    overflowX: "hidden",
  },
  content: {
    flexGrow: 1,
    marginLeft: ({ sidebarWidth }) => sidebarWidth,
    transition: theme.transitions.create("marginLeft", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    position: "relative",
    padding: theme.spacing(4),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 3,
    maxHeight: 65,
  },
  listItemIcon: ({ isMinimized }) => ({
    display: isMinimized ? "flex" : "block",
    justifyContent: "center",
    paddingRight: isMinimized ? "20px" : 0,
  }),
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    opacity: 0.5,
  },
  contentWithoutNav: {
    flexGrow: 1,
    transition: theme.transitions.create("marginLeft", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    position: "relative",
    padding: theme.spacing(4),
  },
}));

interface AppShellProps {
  children: React.ReactNode;
  title: React.ReactNode;
  acceptedRoles: string[];
  backgroundColor?: "gray" | "white";
  hideShell?: boolean;
  hideSidebar?: boolean;
  closedSidebar?: boolean;
}

interface SchedulingComplianceListItemProps {
  isMinimized: boolean;
  clinicianEmail: string;
}

export const SchedulingComplianceListItem = ({
  clinicianEmail,
  isMinimized,
}: SchedulingComplianceListItemProps) => {
  const router = useRouter();
  const { data } = useMembersNotAssignedToRecommendedProtocol(clinicianEmail, {
    enabled: !isMinimized ? false : undefined,
  });

  return (
    <ListItem
      button
      onClick={() => {
        return router.push("/scheduling/compliance");
      }}
    >
      <Tooltip
        placement="right"
        title={isMinimized ? "Protocol Deviations" : ""}
      >
        <ListItemIcon
          style={{
            display: isMinimized ? "flex" : "block",
            justifyContent: "center",
            paddingRight: isMinimized ? "20px" : 0,
          }}
        >
          <Badge badgeContent={data?.missing_count ?? 0} color="error">
            <ChecklistOutlinedIcon />
          </Badge>
        </ListItemIcon>
      </Tooltip>
      {!isMinimized && <ListItemText primary="Protocol Deviations" />}
    </ListItem>
  );
};

export default function AppShellV2({
  children,
  title,
  acceptedRoles,
  backgroundColor = "gray",
  hideShell = false,
  hideSidebar = false,
  closedSidebar = false,
}: AppShellProps): JSX.Element {
  const { data: session, isLoading: isAuthenticating } = useSession();
  const { accessToken: localAccessToken } = session ?? {};
  const { data: user, error: userError } = useUser();

  const isUserAuthenticated =
    !!localAccessToken &&
    userError?.name !== APIErrorTypeV3.INVALID_GOOGLE_AUTH;

  const { associateProgramEnabled, enableSessionhealth } = useFlags();

  const isClinician = Boolean(user?.roles?.includes(EhrRoles.CLINICIAN));
  const isClinicianPro = Boolean(user?.roles?.includes(EhrRoles.CLINICIAN_PRO));

  const isMemberAdvocate = Boolean(
    user?.roles?.includes(EhrRoles.MEMBER_ADVOCATE)
  );
  const isMemberAdvocateAuditor = Boolean(
    user?.roles?.includes(EhrRoles.MEMBER_ADVOCATE_AUDITOR)
  );
  const isScheduler = Boolean(user?.roles?.includes(EhrRoles.SCHEDULING));
  const isClinicianScheduleApprover = Boolean(
    user?.roles?.includes(EhrRoles.CLINICIAN_SCHEDULE_APPROVER)
  );

  const isClinicianSupervisor = user?.roles?.includes(
    EhrRoles.CLINICIAN_SUPERVISOR
  );
  const isClinicalLeadSpecialtyConditions = user?.roles?.includes(
    EhrRoles.CLINICAL_LEAD_SPECIALTY_CONDITIONS
  );
  const isFormBaselineChangeRequestAdmin = user?.roles?.includes(
    EhrRoles.FORM_BASELINE_CHANGE_REQUEST_ADMIN
  );
  const isAssessmentArchiveChangeRequestAdmin = user?.roles?.includes(
    EhrRoles.ASSESSMENT_ARCHIVE_CHANGE_REQUEST_ADMIN
  );
  const isClinicalLeadership = user?.roles?.includes(
    EhrRoles.CLINICAL_LEADERSHIP
  );
  const isDiagnosticApprover = user?.roles?.includes(
    EhrRoles.DIAGNOSTIC_APPROVER
  );

  const isIntake = Boolean(user?.roles?.includes(EhrRoles.INTAKE));
  const hasAccess = acceptedRoles
    ? Boolean(!isEmpty(intersection(user?.roles || [], acceptedRoles)))
    : true;

  const { push } = useRouter();

  const {
    isNavigationDrawerOpen,
    openNavigationDrawer,
    closeNavigationDrawer,
    isMinimized,
    smallScreen,
    sidebarWidth,
  } = useNavigationDrawer();

  const classes = useStyles({ sidebarWidth, isMinimized });

  return (
    <Box
      key="app-shell-sidebar-intergated"
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      bgcolor={backgroundColor === "white" ? "white" : "grey.200"}
    >
      <SideEffects />

      {!hideShell ? (
        <AppBar
          className={classes.appBar}
          sx={{ backgroundColor: isClinicianPro ? "#6e76ee" : "#00a3ad" }}
        >
          <Toolbar>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              className={classes.title}
            >
              <Link href="/" passHref>
                <a>{title}</a>
              </Link>
              {isClinicianPro ? (
                <Image
                  src={ProBadge as string}
                  alt="NOTO Logo"
                  width={30}
                  height={30}
                />
              ) : null}
            </Stack>

            {isUserAuthenticated ? (
              <UserMenu
                avatar={session?.user?.image}
                email={session?.user?.email}
              />
            ) : (
              <Button color="inherit" onClick={() => signIn("google")}>
                Log in
              </Button>
            )}
          </Toolbar>
        </AppBar>
      ) : null}

      {!hideSidebar && isUserAuthenticated ? (
        <Drawer
          variant="permanent"
          anchor="left"
          onClose={closeNavigationDrawer}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          <div className={classes.drawerHeader}>
            {isNavigationDrawerOpen ? (
              <IconButton
                onClick={closeNavigationDrawer}
                style={{ display: !smallScreen ? "flex" : "none" }}
              >
                <ChevronLeftIcon />
              </IconButton>
            ) : (
              <IconButton
                onClick={openNavigationDrawer}
                disabled={closedSidebar}
                style={{ display: !smallScreen ? "flex" : "none" }}
              >
                <ChevronRightIcon />
              </IconButton>
            )}
          </div>
          <Divider />
          <div className={classes.drawerContainer}>
            <List>
              <List>
                <ListItem
                  button
                  onClick={() => {
                    return push("/");
                  }}
                >
                  <Tooltip placement="right" title={isMinimized ? "Home" : ""}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <HomeOutlinedIcon />
                    </ListItemIcon>
                  </Tooltip>
                  {!isMinimized && <ListItemText primary="Home" />}
                </ListItem>
              </List>
              {isScheduler ? (
                <List
                  subheader={
                    !isMinimized ? (
                      <ListSubheader disableSticky>Scheduling</ListSubheader>
                    ) : (
                      <Divider />
                    )
                  }
                >
                  <ListItem
                    button
                    onClick={() => {
                      return push("/clinicians");
                    }}
                  >
                    <Tooltip
                      placement="right"
                      title={isMinimized ? "Scheduling" : ""}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <CalendarMonthOutlinedIcon />
                      </ListItemIcon>
                    </Tooltip>
                    {!isMinimized && <ListItemText primary="Scheduling" />}
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      return push("/scheduling/members");
                    }}
                  >
                    <Tooltip
                      placement="right"
                      title={isMinimized ? "Members" : ""}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <GroupsOutlinedIcon />
                      </ListItemIcon>
                    </Tooltip>
                    {!isMinimized && <ListItemText primary="Members" />}
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      return push("/clinicians/explore");
                    }}
                  >
                    <Tooltip
                      placement="right"
                      title={isMinimized ? "Clinicians" : ""}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <MedicalServicesOutlinedIcon />
                      </ListItemIcon>
                    </Tooltip>
                    {!isMinimized && <ListItemText primary="Clinicians" />}
                  </ListItem>

                  {isClinicianScheduleApprover ? (
                    <ListItem
                      button
                      onClick={() => {
                        return push("/clinicians/availability-requests");
                      }}
                    >
                      <Tooltip
                        placement="right"
                        title={isMinimized ? "Availability Requests" : ""}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          <ViewListOutlinedIcon />
                        </ListItemIcon>
                      </Tooltip>
                      {!isMinimized && (
                        <ListItemText primary="Availability Requests" />
                      )}
                    </ListItem>
                  ) : null}

                  {isClinicalLeadership ? (
                    <ListItem
                      button
                      onClick={() => {
                        return push("/debug/member-finder");
                      }}
                    >
                      <Tooltip
                        placement="right"
                        title={isMinimized ? "Member Finder" : ""}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          <PersonSearchOutlinedIcon />
                        </ListItemIcon>
                      </Tooltip>
                      {!isMinimized && <ListItemText primary="Member Finder" />}
                    </ListItem>
                  ) : null}

                  <ListItem
                    button
                    onClick={() => {
                      return push("/transfers/requests");
                    }}
                  >
                    <Tooltip
                      placement="right"
                      title={isMinimized ? "Transfer Requests" : ""}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <CompareArrowsOutlinedIcon />
                      </ListItemIcon>
                    </Tooltip>
                    {!isMinimized && (
                      <ListItemText primary="Transfer Requests" />
                    )}
                  </ListItem>

                  <ListItem
                    button
                    onClick={() => {
                      return push("/member-experience");
                    }}
                  >
                    <Tooltip
                      placement="right"
                      title={isMinimized ? "Member Experience" : ""}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <StarOutlineOutlinedIcon />
                      </ListItemIcon>
                    </Tooltip>
                    {!isMinimized && (
                      <ListItemText primary="Member Experience" />
                    )}
                  </ListItem>
                </List>
              ) : null}

              {isClinician ? (
                <List
                  subheader={
                    !isMinimized ? (
                      <ListSubheader disableSticky>Clinician</ListSubheader>
                    ) : (
                      <Divider />
                    )
                  }
                >
                  {["Resigned", "Retired"].includes(user.current_status) ? (
                    <ListItem
                      button
                      onClick={() => {
                        return push("/succession");
                      }}
                    >
                      <Tooltip
                        placement="right"
                        title={isMinimized ? "Plan of succession" : ""}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          <RepeatOutlinedIcon />
                        </ListItemIcon>
                      </Tooltip>
                      {!isMinimized && (
                        <ListItemText primary="Plan of succession" />
                      )}
                    </ListItem>
                  ) : null}

                  <ListItem
                    button
                    onClick={() => {
                      return push("/appointments");
                    }}
                  >
                    <Tooltip
                      placement="right"
                      title={isMinimized ? "My Appointments" : ""}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <CalendarTodayOutlinedIcon />
                      </ListItemIcon>
                    </Tooltip>
                    {!isMinimized && <ListItemText primary="My Appointments" />}
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      return push("/members");
                    }}
                  >
                    <Tooltip
                      placement="right"
                      title={isMinimized ? "My Members" : ""}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <GroupsOutlinedIcon />
                      </ListItemIcon>
                    </Tooltip>
                    {!isMinimized && <ListItemText primary="My Members" />}
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      return push("/availability");
                    }}
                  >
                    <Tooltip
                      placement="right"
                      title={isMinimized ? "My Availability" : ""}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <ScheduleOutlinedIcon />
                      </ListItemIcon>
                    </Tooltip>
                    {!isMinimized && <ListItemText primary="My Availability" />}
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      return push("/credentials");
                    }}
                  >
                    <Tooltip
                      placement="right"
                      title={isMinimized ? "My Credentials" : ""}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <BadgeOutlinedIcon />
                      </ListItemIcon>
                    </Tooltip>
                    {!isMinimized && <ListItemText primary="My Credentials" />}
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      return push("/messaging");
                    }}
                  >
                    <Tooltip
                      placement="right"
                      title={isMinimized ? "Messaging" : ""}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <ChatOutlinedIcon />
                      </ListItemIcon>
                    </Tooltip>
                    {!isMinimized && <ListItemText primary="Messaging" />}
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      return push("/progress");
                    }}
                  >
                    <Tooltip
                      placement="right"
                      title={isMinimized ? "My Progress" : ""}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <TrendingUpOutlinedIcon />
                      </ListItemIcon>
                    </Tooltip>
                    {!isMinimized && (
                      <ListItemText
                        primary={
                          <>
                            My Progress{" "}
                            <Chip
                              sx={{ ml: 1 }}
                              label="New"
                              size="small"
                              color="primary"
                            />
                          </>
                        }
                      />
                    )}
                  </ListItem>
                  {enableSessionhealth && (
                    <ListItem
                      button
                      onClick={() => {
                        return push("/session-health");
                      }}
                    >
                      <Tooltip
                        placement="right"
                        title={isMinimized ? "Session Health" : ""}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          <MonitorHeartOutlinedIcon />
                        </ListItemIcon>
                      </Tooltip>

                      {!isMinimized && (
                        <ListItemText
                          primary={
                            <>
                              Session Health
                              <Chip
                                sx={{ ml: 1 }}
                                label="New"
                                size="small"
                                color="primary"
                              />
                            </>
                          }
                        />
                      )}
                    </ListItem>
                  )}
                  <ListItem
                    button
                    onClick={() => {
                      return push("/member-finder");
                    }}
                  >
                    <Tooltip
                      placement="right"
                      title={isMinimized ? "Member Finder" : ""}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <SearchOutlinedIcon />
                      </ListItemIcon>
                    </Tooltip>
                    {!isMinimized && <ListItemText primary="Member Finder" />}
                  </ListItem>

                  <ListItem
                    button
                    onClick={() => {
                      return push("/my/priority-waitlists");
                    }}
                  >
                    <Tooltip
                      placement="right"
                      title={isMinimized ? "My Priority Waitlist" : ""}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <HourglassEmptyOutlinedIcon />
                      </ListItemIcon>
                    </Tooltip>
                    {!isMinimized && (
                      <ListItemText primary="My Priority Waitlist" />
                    )}
                  </ListItem>

                  <ListItem
                    button
                    onClick={() => {
                      return push("/moveups");
                    }}
                  >
                    <Tooltip
                      placement="right"
                      title={
                        isMinimized ? "Move Up/Wait List Opportunities" : ""
                      }
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <AssignmentOutlinedIcon />
                      </ListItemIcon>
                    </Tooltip>
                    {!isMinimized && (
                      <ListItemText primary="Move Up/Wait List Opportunities" />
                    )}
                  </ListItem>

                  {isClinician ? (
                    <SchedulingComplianceListItem
                      isMinimized={isMinimized}
                      clinicianEmail={user.email}
                    />
                  ) : null}

                  {isDiagnosticApprover ? (
                    <ListItem
                      button
                      onClick={() => {
                        return push(`/diagnosis-approver`);
                      }}
                    >
                      <Tooltip
                        placement="right"
                        title={isMinimized ? "Diagnosis Approval" : ""}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          <DashboardOutlinedIcon />
                        </ListItemIcon>
                      </Tooltip>
                      {!isMinimized && (
                        <ListItemText primary="Diagnosis Approval" />
                      )}
                    </ListItem>
                  ) : null}
                </List>
              ) : null}

              {isClinicianSupervisor && associateProgramEnabled ? (
                <List
                  subheader={
                    !isMinimized ? (
                      <ListSubheader disableSticky>Supervisor</ListSubheader>
                    ) : (
                      <Divider />
                    )
                  }
                >
                  <ListItem
                    button
                    onClick={() => {
                      return push(
                        `/supervisor?tab=${encodeURIComponent(
                          "Associate Appointments"
                        )}`
                      );
                    }}
                  >
                    <Tooltip
                      placement="right"
                      title={isMinimized ? "Supervisor" : ""}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <DashboardOutlinedIcon />
                      </ListItemIcon>
                    </Tooltip>
                    {!isMinimized && <ListItemText primary="Supervisor" />}
                  </ListItem>
                </List>
              ) : null}

              {(isClinicalLeadSpecialtyConditions ||
                isFormBaselineChangeRequestAdmin ||
                isAssessmentArchiveChangeRequestAdmin) && (
                <List
                  subheader={
                    !isMinimized ? (
                      <ListSubheader disableSticky>Admin</ListSubheader>
                    ) : (
                      <Divider />
                    )
                  }
                >
                  {isClinicalLeadSpecialtyConditions ? (
                    <ListItem
                      button
                      onClick={() => {
                        return push(`/clinician-specialty-conditions`);
                      }}
                    >
                      <Tooltip
                        placement="right"
                        title={
                          isMinimized ? "Clinician Specialty Conditions" : ""
                        }
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          <StarsOutlinedIcon />
                        </ListItemIcon>
                      </Tooltip>
                      {!isMinimized && (
                        <ListItemText primary="Clinician Specialty Conditions" />
                      )}
                    </ListItem>
                  ) : null}
                  {isFormBaselineChangeRequestAdmin ? (
                    <ListItem
                      button
                      onClick={() => {
                        return push(`/form_baseline_requests_dashboard`);
                      }}
                    >
                      <Tooltip
                        placement="right"
                        title={isMinimized ? "Form Baseline Requests" : ""}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          <DescriptionOutlinedIcon />
                        </ListItemIcon>
                      </Tooltip>
                      {!isMinimized && (
                        <ListItemText primary="Form Baseline Requests" />
                      )}
                    </ListItem>
                  ) : null}

                  {isAssessmentArchiveChangeRequestAdmin ? (
                    <ListItem
                      button
                      onClick={() => {
                        return push(`/form_archive_requests_dashboard`);
                      }}
                    >
                      <Tooltip
                        placement="right"
                        title={isMinimized ? "Assessment Archive Requests" : ""}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          <ChangeCircleOutlinedIcon />
                        </ListItemIcon>
                      </Tooltip>
                      {!isMinimized && (
                        <ListItemText primary="Assessment Archive Requests" />
                      )}
                    </ListItem>
                  ) : null}
                </List>
              )}

              {isMemberAdvocate || isMemberAdvocateAuditor || isIntake ? (
                <List
                  subheader={
                    !isMinimized ? (
                      <ListSubheader disableSticky>Care Team</ListSubheader>
                    ) : (
                      <Divider />
                    )
                  }
                >
                  {isIntake ? (
                    <ListItem
                      button
                      onClick={() => {
                        return push("/intake");
                      }}
                    >
                      <Tooltip
                        placement="right"
                        title={isMinimized ? "Intake" : ""}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          <HeadsetMicOutlinedIcon />
                        </ListItemIcon>
                      </Tooltip>
                      {!isMinimized && <ListItemText primary="Intake" />}
                    </ListItem>
                  ) : null}
                  {isIntake ? (
                    <ListItem
                      button
                      onClick={() => {
                        return push(
                          `/referrals?tab=${encodeURIComponent(
                            "Inbound Referrals"
                          )}`
                        );
                      }}
                    >
                      <Tooltip
                        placement="right"
                        title={isMinimized ? "Inbound Referrals" : ""}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          <CallReceivedOutlinedIcon />
                        </ListItemIcon>
                      </Tooltip>
                      {!isMinimized && (
                        <ListItemText primary="Inbound Referrals" />
                      )}
                    </ListItem>
                  ) : null}
                  {isMemberAdvocate ? (
                    <ListItem
                      button
                      onClick={() => {
                        return push("/member-advocate-messaging");
                      }}
                    >
                      <Tooltip
                        placement="right"
                        title={isMinimized ? "Member Advocate Messaging" : ""}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          <ChatOutlinedIcon />
                        </ListItemIcon>
                      </Tooltip>
                      {!isMinimized && (
                        <ListItemText primary="Member Advocate Messaging" />
                      )}
                    </ListItem>
                  ) : null}
                  {isMemberAdvocateAuditor ? (
                    <ListItem
                      button
                      onClick={() => {
                        return push("/audit-member-advocate-messaging");
                      }}
                    >
                      <Tooltip
                        placement="right"
                        title={isMinimized ? "Audit Advocate Messaging" : ""}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          <VisibilityOutlinedIcon />
                        </ListItemIcon>
                      </Tooltip>
                      {!isMinimized && (
                        <ListItemText primary="Audit Advocate Messaging" />
                      )}
                    </ListItem>
                  ) : null}
                </List>
              ) : null}
              <List
                subheader={
                  !isMinimized ? (
                    <ListSubheader disableSticky>Resources</ListSubheader>
                  ) : (
                    <Divider />
                  )
                }
              >
                <ListItem
                  button
                  onClick={() => {
                    window.open(
                      `${process.env.NEXT_PUBLIC_WEB_APP_BASE_URL}/login?redirectTo=/community`,
                      "_blank"
                    );
                  }}
                >
                  <Tooltip
                    placement="right"
                    title={isMinimized ? "NOCD Community" : ""}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <ForumOutlinedIcon />
                    </ListItemIcon>
                  </Tooltip>
                  {!isMinimized && <ListItemText primary="NOCD Community" />}
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    window.open(
                      `https://sites.google.com/nocdhelp.com/knowledgehub/home`,
                      "_blank"
                    );
                  }}
                >
                  <Tooltip
                    placement="right"
                    title={isMinimized ? "Knowledge Hub" : ""}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <LibraryBooksOutlinedIcon />
                    </ListItemIcon>
                  </Tooltip>
                  {!isMinimized && <ListItemText primary="Knowledge Hub" />}
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    window.open(
                      `https://sites.google.com/nocdhelp.com/people-hub/home`,
                      "_blank"
                    );
                  }}
                >
                  <Tooltip
                    placement="right"
                    title={isMinimized ? "People Hub" : ""}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <CoPresentOutlinedIcon />
                    </ListItemIcon>
                  </Tooltip>
                  {!isMinimized && <ListItemText primary="People Hub" />}
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    window.open(
                      `https://www.treatmyocd.com/therapist-referral-program`,
                      "_blank"
                    );
                  }}
                >
                  <Tooltip
                    placement="right"
                    title={isMinimized ? "Therapist Referral Program" : ""}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <AttachMoneyOutlinedIcon />
                    </ListItemIcon>
                  </Tooltip>
                  {!isMinimized && (
                    <ListItemText primary="Therapist Referral Program" />
                  )}
                </ListItem>
              </List>
            </List>
          </div>
        </Drawer>
      ) : null}

      {!hideShell ? <Toolbar /> : null}

      {/* eslint-disable-next-line no-nested-ternary */}
      {isAuthenticating ? (
        <div className={classes.content} key="authenticating">
          <Grid justifyContent="center" container>
            <CircularProgress color="primary" />
          </Grid>
        </div>
      ) : isUserAuthenticated ? (
        <div
          className={hideSidebar ? classes.contentWithoutNav : classes.content}
          key="authenticated"
        >
          {hasAccess ? children : <AccessDenied isLoading={!user} />}
        </div>
      ) : (
        <div className={classes.contentWithoutNav} key="not-authenticated">
          <NotAuthenticated onClickLogIn={() => signIn("google")} />
        </div>
      )}
    </Box>
  );
}
