import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    nocdBlue: Palette["primary"];
    nocdRed: Palette["primary"];
    nocdOrange: Palette["primary"];
    nocdGreen: Palette["primary"];
  }

  interface PaletteOptions {
    nocdBlue?: PaletteOptions["primary"];
    nocdRed?: PaletteOptions["primary"];
    nocdOrange?: PaletteOptions["primary"];
    nocdGreen?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    nocdBlue: true;
    nocdRed: true;
    nocdOrange: true;
    nocdGreen: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    nocdLightGrayButton: true;
    nocdIndigoButton: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#545CEA",
    },
    secondary: {
      main: "#00A3AD",
    },
    error: {
      main: "#FF5D47",
    },
    background: {
      default: "#FFF",
    },
  },
  components: {
    MuiPaper: {
      defaultProps: {
        variant: "outlined",
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "#424242",
          fontSize: "0.875rem",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "rgba(0, 0, 0, 0.87)",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "nocdLightGrayButton" },
          style: {
            textTransform: "none",
            backgroundColor: "#F1F5F9",
            color: "#0F172A",
            "&:hover": {
              backgroundColor: "#E2E8F0",
            },
            borderRadius: "8px",
            padding: "8px",
            lineHeight: 1,
          },
        },

        {
          props: { variant: "nocdIndigoButton" },
          style: {
            textTransform: "none",
            backgroundColor: "#6E76EE",
            color: "#FFF",
            "&:hover": {
              backgroundColor: "#545CEA",
            },
            borderRadius: "8px",
          },
        },

        {
          props: { variant: "nocdLightGrayButton", size: "small" },
          style: {
            textTransform: "none",
            backgroundColor: "#F1F5F9",
            color: "#0F172A",
            "&:hover": {
              backgroundColor: "#E2E8F0",
            },
            borderRadius: "8px",
            padding: "4px 8px",
            lineHeight: 1,
          },
        },
      ],
    },
  },
});

export default createTheme(theme, {
  palette: {
    nocdBlue: theme.palette.augmentColor({
      color: {
        main: "#2E8EFF",
      },
      name: "nocdBlue",
    }),
    nocdRed: theme.palette.augmentColor({
      color: {
        main: "#FF5D47",
      },
      name: "nocdRed",
    }),
    nocdOrange: theme.palette.augmentColor({
      color: {
        main: "#F5A623",
      },
      name: "nocdOrange",
    }),
    nocdGreen: theme.palette.augmentColor({
      color: {
        main: "#22C55E",
      },
      name: "nocdGreen",
    }),
  },
});
