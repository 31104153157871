import { ChatOutlined, Close } from "@mui/icons-material";
import { Badge, Box, IconButton, Typography } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { sumBy } from "lodash";
import Link from "next/link";
import { useRouter } from "next/router";

import { useWebNotifications } from "../hooks/useWebNotifications";
import { useDismissWebNotification } from "../mutations/useDismissWebNotification";

function NotificationAlerts(): JSX.Element {
  const { enableNotoWebMessagingNotifications } = useFlags();

  const router = useRouter();
  const protectedRoutes = ["/messaging", "/appointments/[appointmentId]"];

  const { data: newNotifications } = useWebNotifications();
  const dismissNotification = useDismissWebNotification();

  if (
    !newNotifications ||
    !enableNotoWebMessagingNotifications ||
    protectedRoutes.includes(router.pathname)
  ) {
    return null;
  }

  const shouldShowBox =
    Boolean(newNotifications.web_alert_new_message.length) ||
    Boolean(newNotifications.web_alert_24_hours_waiting.length) ||
    Boolean(newNotifications.web_alert_48_hours_waiting.length);

  return (
    <>
      {shouldShowBox && (
        <Box
          display="flex"
          flexDirection="column"
          position="fixed"
          alignItems="flex-start"
          gap={1}
          bottom={20}
          left={40}
          zIndex={10000}
        >
          {Boolean(newNotifications.web_alert_new_message.length) && (
            <Box
              p={3}
              display="flex"
              alignItems="flex-start"
              gap={3}
              style={{ backgroundColor: "#E5F6FD" }}
              borderRadius={2}
              boxShadow={2}
            >
              <Badge
                badgeContent={sumBy(
                  newNotifications.web_alert_new_message,
                  (x) => x.unreads_in_channel
                )}
                max={9}
                color="primary"
              >
                <ChatOutlined color="primary" />
              </Badge>

              <Box display="flex" flexDirection="column" mr={3}>
                <Typography variant="body1" fontWeight="bold">
                  Unread message
                </Typography>

                <Typography variant="body2">
                  You have a new message from a member.{" "}
                  <Link href="/messaging" target="_blank">
                    Let&apos;s help them out!
                  </Link>
                </Typography>
              </Box>

              <IconButton
                edge="end"
                sx={{ p: 0 }}
                onClick={async () => {
                  await dismissNotification.mutateAsync({
                    alertType: "web_alert_new_message",
                  });
                }}
              >
                <Close fontSize="small" />
              </IconButton>
            </Box>
          )}

          {Boolean(newNotifications.web_alert_24_hours_waiting.length) && (
            <Box
              p={3}
              display="flex"
              alignItems="flex-start"
              gap={3}
              style={{ backgroundColor: "rgb(250, 144, 20)" }}
              borderRadius={2}
              boxShadow={2}
            >
              <Badge
                badgeContent={
                  newNotifications.web_alert_24_hours_waiting.length
                }
                max={9}
                color="warning"
              >
                <Typography
                  variant="button"
                  fontWeight="bold"
                  border="2px solid black"
                  borderRadius={8}
                  px={0.5}
                >
                  24
                </Typography>
              </Badge>

              <Box display="flex" flexDirection="column" mr={3}>
                <Typography variant="body1" fontWeight="bold">
                  You still have a message that needs a response
                </Typography>

                <Typography variant="body2">
                  It&apos;s been 24 hours since you received messages needing a
                  response.{" "}
                  <Link href="/messaging" target="_blank">
                    Please respond soon
                  </Link>
                </Typography>
              </Box>

              <IconButton
                edge="end"
                sx={{ p: 0 }}
                onClick={async () => {
                  await dismissNotification.mutateAsync({
                    alertType: "web_alert_24_hours_waiting",
                  });
                }}
              >
                <Close fontSize="small" />
              </IconButton>
            </Box>
          )}

          {Boolean(newNotifications.web_alert_48_hours_waiting.length) && (
            <Box
              p={3}
              display="flex"
              alignItems="flex-start"
              gap={3}
              style={{ backgroundColor: "rgb(221, 37, 57)" }}
              borderRadius={2}
              boxShadow={2}
            >
              <Badge
                badgeContent={
                  newNotifications.web_alert_48_hours_waiting.length
                }
                max={9}
                color="error"
              >
                <Typography
                  variant="button"
                  fontWeight="bold"
                  border="2px solid white"
                  color="white"
                  borderRadius={8}
                  px={0.5}
                >
                  48
                </Typography>
              </Badge>

              <Box display="flex" flexDirection="column" mr={3}>
                <Typography variant="body1" fontWeight="bold" color="white">
                  You still have a message that needs a response
                </Typography>

                <Typography variant="body2" color="white">
                  It&apos;s been 48 hours since you received messages needing a
                  response.{" "}
                  <Link href="/messaging" target="_blank" color="white">
                    Please respond soon
                  </Link>
                </Typography>
              </Box>

              <IconButton
                edge="end"
                sx={{ p: 0 }}
                onClick={async () => {
                  await dismissNotification.mutateAsync({
                    alertType: "web_alert_48_hours_waiting",
                  });
                }}
              >
                <Close fontSize="small" sx={{ color: "white" }} />
              </IconButton>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}

export default NotificationAlerts;
