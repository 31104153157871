import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Avatar,
  IconButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
} from "@mui/material";
import { useRouter } from "next/router";
import { signOut } from "next-auth/react";
import { useCallback, useState } from "react";

const useUserMenu = () => {
  const [userMenuAnchorElement, setUserMenuAnchorElement] =
    useState<Element | null>(null);
  const isUserMenuOpen = Boolean(userMenuAnchorElement);

  const openUserMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
      setUserMenuAnchorElement(event.currentTarget),
    []
  );

  const closeUserMenu = useCallback(() => setUserMenuAnchorElement(null), []);

  return {
    userMenuAnchorElement,
    openUserMenu,
    closeUserMenu,
    isUserMenuOpen,
  };
};

export default function UserMenu({
  avatar,
  email,
}: {
  avatar: string | null;
  email: string;
}) {
  const { push } = useRouter();
  const { closeUserMenu, openUserMenu, isUserMenuOpen, userMenuAnchorElement } =
    useUserMenu();

  return (
    <div>
      <IconButton aria-haspopup="true" onClick={openUserMenu} sx={{ p: 0 }}>
        <Avatar src={avatar} alt={email ?? ""} />
      </IconButton>

      <Menu
        anchorEl={userMenuAnchorElement}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ mt: "45px" }}
        open={isUserMenuOpen}
        onClose={closeUserMenu}
        MenuListProps={{
          subheader: email ? (
            <ListSubheader component="div">{email}</ListSubheader>
          ) : null,
        }}
      >
        <MenuItem
          onClick={() => {
            closeUserMenu();
            return push("/my/settings");
          }}
        >
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Settings</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            closeUserMenu();
            return signOut();
          }}
        >
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Log out</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
