import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { SupervisorNote } from "@core/services/nocd-api/clinicians";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import invariant from "tiny-invariant";

interface CreateSupervisorContactPayload {
  clinician_supervisor_email: string;
  note_date: string;
  note_text: string;
  clinician_associate_user_id: number;
  clinician_associate_email: string;
}

const getSupervisorContactNoteQueryKey = (
  accessToken: string
): [string, string] => [`supervisor-contact-notes`, accessToken];

const createSupervisorContactNote = (
  payload: CreateSupervisorContactPayload,
  accessToken: string | undefined
) => {
  invariant(
    payload.clinician_associate_user_id != null,
    "associate user_id is required"
  );
  return api
    .post<SupervisorNote[]>(
      `/v1/clinicians/supervisor_contact_notes`,
      {
        ...payload,
        note_date: payload.note_date
          ? new Date(payload.note_date).toISOString()
          : undefined,
      },
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);
};

export const useCreateSupervisorContactNote = (): UseMutationResult<
  SupervisorNote[],
  Error,
  CreateSupervisorContactPayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => createSupervisorContactNote(payload, accessToken),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(
          getSupervisorContactNoteQueryKey(accessToken)
        ),
    }
  );
};
