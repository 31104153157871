import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { Appointment } from "@core/services/nocd-api/appointments";
import { useQuery, UseQueryResult } from "react-query";

const getAssociatesAppointments = (accessToken: string, signal?: AbortSignal) =>
  api
    .get<Appointment[]>(`/v2/clinician/associate_appointments`, {
      headers: {
        Authorization: accessToken,
      },
      signal,
    })
    .then(({ data }) => data)
    .catch(transformAPIError);

export const useAssociatesAppointments = (): UseQueryResult<
  Appointment[],
  Error
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    ["associates-appointments", session?.accessToken],
    ({ signal }) => getAssociatesAppointments(accessToken, signal),
    {
      enabled: !!accessToken,
    }
  );
};
